<template>
  <div>&nbsp;</div>
</template>

<script>
export default {
  props: {
    isEditing: {
      type: Boolean,
      required: false,
    },
    isMobile: {
      type: Boolean,
    },
    pdfType: {
      type: String,
    },
  },
};
</script>
